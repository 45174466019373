import { FC } from "react";
import { EditForm } from "~/components/jobseeker/EditForm";
import { skill } from "~/types/jobseeker";
import SkillForm from "./Skill";
import { Button } from "~/components/v2/Button";

interface SkillProps {
  data?: skill[];
  uid?: number;
  onSubmit: () => void;
}

const SkillPreliminary: FC<SkillProps> = ({ data = [], onSubmit, uid }) => {
  return (
    <EditForm title="Skills and Expertises" subTitle="Edit" hideFooter>
      <div className="mt-4 px-1 mb-6">
        {data.map((skill) => (
          <SkillItem key={skill.id} data={skill} refetch={onSubmit} />
        ))}
      </div>
      <SkillForm uid={uid} onSubmit={onSubmit} />
      
    </EditForm>
  );
};

interface SkillItemProps {
  data: skill;
  refetch: () => void;
}

const SkillItem: FC<SkillItemProps> = ({ data, refetch }) => {
  return (
    <div className="flex flex-col last:after:border-0 last:after:m-0 after:content-[''] after:self-stretch after:border-b after:border-[#ccc] after:my-1">
      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-end">
          <span className="text-sm font-bold text-[#000D3D]">
            {data.skill_name}
          </span>
          <span className="text-xs text-[#595959]">
            <span className="capitalize text-xs">
              {data.skill_level?.toLowerCase()}
            </span>{" "}
            • {data.year_of_experience} years
          </span>
        </div>
        <SkillForm uid={data?.seekerId} data={data} onSubmit={refetch} />
      </div>
    </div>
  );
};

export default SkillPreliminary;
