import { z } from "zod";

export const formSchema = z.object({
  issuing_authority: z
    .string()
    .nonempty("Please enter issuing organization / authority"),
  award_name: z.string().nonempty("Please enter achievement / award name"),
  year_issued: z.number({
    required_error: "Please enter year issued",
    invalid_type_error: "Please enter year issued",
  }),
  description: z.string().optional().nullable(),
});
