import { FC } from "react";
import { Controller } from "react-hook-form";
import {
  EditForm,
  NumberField,
  TextAreaField,
  TextField,
} from "~/components/jobseeker/EditForm";
import { useCreateCertificate } from "~/hooks/jobseeker/useCreateCertificate";
import { useDeleteCertificate } from "~/hooks/jobseeker/useDeleteCertificate";
import { useUpdateCertificate } from "~/hooks/jobseeker/useUpdateCertificate";
import useCustomForm from "~/hooks/useCustomForm";
import Add from "~/icons/Add";
import { certificate } from "~/types/jobseeker";
import { formSchema } from "./schema";

interface CertificateProps {
  data?: certificate;
  uid?: number;
  onSubmit: () => void;
}

const Certificate: FC<CertificateProps> = ({ data, onSubmit, uid }) => {
  const { mutateAsync: update } = useUpdateCertificate();
  const { mutateAsync: create } = useCreateCertificate();
  const { mutateAsync: deleteItem, isPending: isDeleting } =
    useDeleteCertificate();
  const {
    register,
    handleSubmit,
    errorMessages,
    control,
    submit,
    isDirty,
    handleDelete,
    reset,
    isValid,
  } = useCustomForm({
    update: newData => update({ uid: uid!, data: newData }),
    create: newData =>
      create({
        uid: uid!,
        data: newData,
      }),
    deleteItem: id => deleteItem({ uid: uid!, id }),
    formSchema,
    data,
    onSubmit,
  });

  return (
    <EditForm
      title="Certificate or license"
      subTitle={!!data ? "Edit" : "Add new"}
      onSubmit={submit}
      submitHandler={handleSubmit}
      iconTrigger={!data ? <Add /> : undefined}
      isDirty={isDirty}
      reset={reset}
      onDelete={!!data ? handleDelete : undefined}
      isDeleting={isDeleting}
      isValid={isValid}
      needsValidity={!!data}
    >
      <TextField
        label="Certificate / License Name"
        {...register("certificate_license")}
        error={errorMessages.certificate_license}
        required
      />
      <TextField
        label="Issuing Organization / Authority"
        {...register("issuing_authority")}
        error={errorMessages.issuing_authority}
        required
      />
      <Controller
        name="year_issued"
        control={control}
        render={({ field: { onChange, name, value, onBlur } }) => (
          <NumberField
            name={name}
            ref={register("year_issued").ref}
            value={value}
            onValueChange={values =>
              onChange({ target: { name, value: values.floatValue || 0 } })
            }
            label="Year Issued"
            error={errorMessages.year_issued}
            required
            onBlur={onBlur}
          />
        )}
      />
      <TextAreaField
        {...register("description")}
        label="Description"
        subText="This description will not be visible to the public, and is designed for job matching purposes only - please be as descriptive as possible regarding your role, your responsibilities, your accomplishments, etc."
        error={errorMessages.description}
      />
    </EditForm>
  );
};

export default Certificate;
