import { z } from "zod";
import { demographicsFormSchema } from "~/components/forms/v2/jobseeker/Demographics";
import axios from "~/services/axios.service";

export const updateDemographics = ({
  uid,
  data,
}: {
  uid: number;
  data: Partial<z.infer<typeof demographicsFormSchema> & { id: number }>;
}) =>
  axios
    .put(`/api/jobseeker/demographics/${uid}`, data)
    .then(response => response.data)
    .catch(e => e.message);
