import { DegreeLevel, Educations } from "@prisma/client";
import { isAfter } from "date-fns";

const sort = (a: Educations, b: Educations) => {
  if (
    isAfter(
      new Date(a.start_year!, a.start_month!),
      new Date(b.start_year!, b.start_month!),
    )
  )
    return -1;
  return 1;
};

const educationLevelInOrder = [
  DegreeLevel.DOCTORATE,
  DegreeLevel.MASTERS,
  DegreeLevel.BACHELORS,
  DegreeLevel.POSTDOCTORAL_STUDIES,
  DegreeLevel.ASSOCIATES,
  DegreeLevel.ADVANCED_CERTIFICATE,
  DegreeLevel.CERTIFICATE,
  DegreeLevel.TECHNICAL_DIPLOMA,
  DegreeLevel.HIGH_SCHOOL,
  DegreeLevel.NON__DEGREE_SEEKING,
];

const getLastEducation = (educations?: Educations[]) => {
  if (!educations || !educations.length) return null;
  for (const level of educationLevelInOrder) {
    const filteredEducations = educations.filter(
      (education) => education.degree === level,
    );
    if (filteredEducations.length) {
      return filteredEducations.sort(sort)[0];
    }
  }
  return null;
};

export default getLastEducation;
