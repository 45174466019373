import { z } from "zod";
import { formSchema } from "~/components/forms/v2/jobseeker/Skill/schema";
import axios from "~/services/axios.service";

export const updateSkill = ({
  uid,
  data,
}: {
  uid: number;
  data: Partial<z.infer<typeof formSchema> & { id: number }>;
}) =>
  axios
    .put(`/api/jobseeker/skill/${uid}`, data)
    .then(response => response.data)
    .catch(e => e.message);
