export const jobTypeOptions = [
  { value: "FULL_TIME", label: "Full-time" },
  { value: "PART_TIME", label: "Part-time" },
  { value: "SEASONAL", label: "Seasonal" },
  { value: "CONTRACTOR", label: "Contractor" },
  { value: "INTERNSHIP", label: "Internship" },
  { value: "PER_DIEM", label: "Per diem" },
  { value: "TEMPORARY", label: "Temporary" },
  { value: "OTHER", label: "Other" },
];

export const locationTypeOptions = [
  { value: "IN_PERSON", label: "In-person" },
  { value: "REMOTE", label: "Remote" },
  { value: "HYBRID", label: "Hybrid" },
];

export const sizeMap: {
  [key: string]: {
    how_large_team_min: number | null;
    how_large_team_max: number | null;
  };
} = {
  "0": { how_large_team_min: null, how_large_team_max: null },
  "1_TO_2": { how_large_team_min: 1, how_large_team_max: 2 },
  "3_TO_5": { how_large_team_min: 3, how_large_team_max: 5 },
  "6_TO_10": { how_large_team_min: 6, how_large_team_max: 10 },
  "11_TO_20": { how_large_team_min: 11, how_large_team_max: 20 },
  "21": { how_large_team_min: 21, how_large_team_max: null },
};
