import { FC, HTMLAttributes, PropsWithChildren } from "react";
import { cn } from "~/utils/cn";

export const Card: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={cn(
        "flex w-full flex-col gap-4 bg-white px-5 py-4 md:rounded-xl",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export const CardTitle: FC<
  PropsWithChildren<HTMLAttributes<HTMLDivElement>>
> = ({ children, className, ...props }) => {
  return (
    <div
      className={cn(
        "flex justify-between text-xl font-semibold text-typography-high-contrast",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
