const Add = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0002 3.33331C10.4604 3.33331 10.8335 3.70641 10.8335 4.16665V9.16665H15.8335C16.2937 9.16665 16.6668 9.53975 16.6668 9.99998C16.6668 10.4602 16.2937 10.8333 15.8335 10.8333H10.8335V15.8333C10.8335 16.2936 10.4604 16.6666 10.0002 16.6666C9.53993 16.6666 9.16683 16.2936 9.16683 15.8333V10.8333H4.16683C3.70659 10.8333 3.3335 10.4602 3.3335 9.99998C3.3335 9.53975 3.70659 9.16665 4.16683 9.16665H9.16683V4.16665C9.16683 3.70641 9.53993 3.33331 10.0002 3.33331Z"
      fill="currentcolor"
    />
  </svg>
);

export default Add;
