import { Skeleton } from "../v2/Skeleton";

const LoadingItem = () => {
  return (
    <div className="flex flex-col gap-1">
      <Skeleton className="h-4 w-52 md:w-96"></Skeleton>
      <Skeleton className="h-3 w-32 md:w-64 mt-1"></Skeleton>
      <Skeleton className="h-3 w-24 md:w-60"></Skeleton>
      <Skeleton className="h-3 w-36 md:w-72"></Skeleton>
    </div>
  );
};

export default LoadingItem;
