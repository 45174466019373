import { z } from "zod";
import { formSchema } from "~/components/forms/v2/jobseeker/Achievement/schema";
import axios from "~/services/axios.service";

export const createAchievement = ({
  uid,
  data,
}: {
  uid: number;
  data: Partial<z.infer<typeof formSchema>>;
}) =>
  axios
    .post(`/api/jobseeker/achievement/${uid}`, data)
    .then(response => response.data)
    .catch(e => e.message);
