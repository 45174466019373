const XTwitter = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M11.8333 1.97363H13.8788L9.41114 7.07866L14.6668 14.0264H10.5527L7.32799 9.8137L3.64264 14.0264H1.59425L6.37189 8.56497L1.3335 1.97363H5.55195L8.46373 5.82413L11.8333 1.97363ZM11.1148 12.8037H12.2476L4.93483 3.13255H3.71797L11.1148 12.8037Z"
      fill="currentColor"
    />
  </svg>
);

export default XTwitter;
