import { Prisma } from "@prisma/client";
import { useMemo } from "react";
import getLastEducation from "~/utils/getLastEducation";

export type JobSeekerAccount = Prisma.JobSeekerAccountGetPayload<{
  include: {
    seeker_profile_header: true;
    job_seeker_basicinfo: true;
    educations: true;
    jobExpericences: true;
  };
}>;

const getCurrentJob = (data?: JobSeekerAccount, fromBasicInfo?: boolean) => {
  if (fromBasicInfo) return data?.job_seeker_basicinfo?.position;
  return data?.seeker_profile_header?.job_title;
};

const useJobAndEducation = (
  data?: JobSeekerAccount,
  fromBasicInfo?: boolean,
) => {
  const lastJob = useMemo(() => {
    const position = getCurrentJob(data, fromBasicInfo);
    if (position) return position;
    if (!data?.jobExpericences || !data.jobExpericences.length) return "";
    const lastJob = data.jobExpericences[0];
    if (!lastJob) return "";
    return `${lastJob.job_title} at ${lastJob.employer}`;
  }, [data]);

  const lastEducation = useMemo(() => {
    if (data?.seeker_profile_header?.education)
      return data?.seeker_profile_header?.education;
    return getLastEducation(data?.educations)?.school_training_institute ?? "";
  }, [data]);

  return { lastJob, lastEducation };
};

export default useJobAndEducation;
