import { FC } from "react";
import EducationForm from "~/components/forms/v2/jobseeker/Education";
import { education } from "~/types/jobseeker";
import { Card, CardTitle } from "../v2/Card";
import { DefaultProps } from "./DefaultProps";
import LoadingItem from "./LoadingItem";

const Education: FC<DefaultProps> = ({ data, loading, refetch, isEdition }) => {
  return (
    <Card className="gap-2 p-4">
      <CardTitle className="text-base">
        Education
        {isEdition && <EducationForm onSubmit={refetch} uid={data?.id} />}
      </CardTitle>
      {loading ? (
        <LoadingItem />
      ) : (
        data?.educations?.map(education => (
          <EducationItem
            key={education.id}
            data={education}
            refetch={refetch}
            isEdition={isEdition}
          />
        ))
      )}
    </Card>
  );
};

interface EducationItemProps {
  data: education;
  refetch: () => void;
  isEdition: boolean;
}

const handleJSONList = (list: any) => {
  if (Array.isArray(list)) return list.join(", ");
  return `${list}`;
};

const degreesWithAreaOfStudy = [
  "ASSOCIATES",
  "MASTERS",
  "CERTIFICATE",
  "ADVANCED_CERTIFICATE",
  "DOCTORATE",
  "POSTDOCTORAL_STUDIES",
  "NON__DEGREE_SEEKING",
  "TECHNICAL_DIPLOMA",
];

const degreesWithGPA = ["HIGH_SCHOOL", "ASSOCIATES", "MASTERS", "BACHELORS"];

const EducationItem: FC<EducationItemProps> = ({
  data,
  refetch,
  isEdition,
}) => {
  return (
    <div className="flex flex-col after:my-4 after:self-stretch after:border-b after:border-[#ccc] after:content-[''] last:after:m-0 last:after:border-0">
      <CardTitle className="text-base font-semibold">
        {data.school_training_institute}{" "}
        {isEdition && (
          <EducationForm uid={data?.seekerId} onSubmit={refetch} data={data} />
        )}
      </CardTitle>
      <div className="flex flex-col py-0">
        <CardTitle className="text-sm font-normal">
          <span>
            <span className="capitalize">
              {data.degree?.replaceAll("_", " ").toLowerCase()}
            </span>{" "}
            {data.degree === "BACHELORS" &&
              ((data.major && data.major?.length > 0) ||
                (data.minor && data.minor?.length > 0)) && (
                <>
                  -{" "}
                  {data.major && (
                    <>
                      Major(s): {handleJSONList(data.major)}
                      <br />
                    </>
                  )}
                  {data.minor && <>Minor(s): {handleJSONList(data.minor)}</>}
                </>
              )}
            {data.degree &&
              degreesWithAreaOfStudy.includes(data.degree) &&
              data.area_of_study &&
              data.area_of_study?.length > 0 &&
              `- Area(s) of Study: ${handleJSONList(data.area_of_study)}`}
          </span>
        </CardTitle>
        <span className="text-xs font-light italic text-[#595959] lg:text-sm">
          {data.start_year} -{" "}
          {data.currently_studying === "YES" ? "Present" : data.end_year}
        </span>
        {data.degree &&
          degreesWithGPA.includes(data.degree) &&
          data.cumulative_gpa && (
            <CardTitle className="text-sm font-normal">
              Cumulative GPA: {data.cumulative_gpa?.toFixed(1)}/
              {data.cumulative_gpa_scale?.toFixed(1)}
            </CardTitle>
          )}
      </div>
    </div>
  );
};

export default Education;
