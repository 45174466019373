import { z } from "zod";

export const formSchema = z.object({
  skill_name: z.string().nonempty("Please enter skill"),
  year_of_experience: z.number({
    required_error: "Please enter number of years of experience",
    invalid_type_error: "Please enter number of years of experience",
  }),
  skill_level: z.string().nonempty("Please select your skill level"),
});
