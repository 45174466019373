import { z } from "zod";
import { formSchema } from "~/components/forms/v2/jobseeker/VolunteerWork/schema";
import axios from "~/services/axios.service";

export const createVolunteerWork = async ({
  uid,
  data,
}: {
  uid: number;
  data: Partial<z.infer<typeof formSchema>>;
}) => await axios.post(`/api/jobseeker/volunteer-work/${uid}`, data);
