import { months } from "moment";
import { FC } from "react";
import {
  EditForm,
  RadioField,
  SelectField,
  TextField,
} from "~/components/jobseeker/EditForm";
import { useCreateJobSeekerEducation } from "~/hooks/jobseeker/useCreateJobSeekerEducation";
import { useDeleteJobSeekerEducation } from "~/hooks/jobseeker/useDeleteJobSeekerEducation";
import { useUpdateJobSeekerEducation } from "~/hooks/jobseeker/useUpdateJobSeekerEducation";
import useCustomForm from "~/hooks/useCustomForm";
import Add from "~/icons/Add";
import { education } from "~/types/jobseeker";
import { degreeFormFields } from "./degreeFormFields";
import { formSchema } from "./schema";
import { degreeOptions } from "./utils";

const MAX_YEARS_IN_THE_FUTURE = 10;

interface EducationProps {
  data?: education;
  uid?: number;
  onSubmit: () => void;
}

const getYearsOptions = (acceptFuture?: boolean) =>
  new Array(200).fill(100).map((_, i) => {
    const year =
      new Date().getFullYear() -
      i +
      (acceptFuture ? MAX_YEARS_IN_THE_FUTURE : 0);
    return {
      label: `${year}`,
      value: `${year}`,
    };
  });

const Education: FC<EducationProps> = ({ data, onSubmit, uid }) => {
  const { mutateAsync: update } = useUpdateJobSeekerEducation();
  const { mutateAsync: create } = useCreateJobSeekerEducation();
  const { mutateAsync: deleteItem, isPending: isDeleting } =
    useDeleteJobSeekerEducation();
  const {
    register,
    handleSubmit,
    errorMessages,
    setValue,
    control,
    watch,
    submit,
    isDirty,
    reset,
    handleDelete,
    isValid,
  } = useCustomForm({
    update: newData => update({ uid: uid!, data: newData }),
    create: newData =>
      create({
        uid: uid!,
        data: newData,
      }),
    deleteItem: id => deleteItem({ uid: uid!, id }),
    formSchema,
    data,
    onSubmit,
  });

  const degree = watch("degree");

  return (
    <EditForm
      title="Education"
      subTitle={!!data ? "Edit" : "Add new"}
      onSubmit={submit}
      submitHandler={handleSubmit}
      iconTrigger={!data ? <Add /> : undefined}
      isDirty={isDirty}
      reset={reset}
      onDelete={!!data ? handleDelete : undefined}
      isDeleting={isDeleting}
      isValid={isValid}
      needsValidity={!data}
    >
      <TextField
        label="School or Training Institution"
        {...register("school_training_institute")}
        error={errorMessages.school_training_institute}
        required
      />
      <RadioField
        control={control}
        label="Are you currently studying or training at this institution?"
        {...register("currently_studying")}
        options={[
          { label: "Yes", value: "YES" },
          { label: "No", value: "NO" },
        ]}
        required
        error={errorMessages.currently_studying}
      />
      <div>
        <span className="text-sm font-semibold">Start date*</span>
        <div className="grid grid-cols-[2fr_1fr] gap-4">
          <SelectField
            control={control}
            name="start_month"
            ref={register("start_month").ref}
            options={months().map((label, i) => ({
              label,
              value: `${i}`,
            }))}
            label=""
            setValue={setValue}
            placeholder="Month"
            searchByLabel
            error={errorMessages.start_month}
          />
          <SelectField
            control={control}
            name="start_year"
            ref={register("start_year").ref}
            options={getYearsOptions()}
            label=""
            setValue={setValue}
            placeholder="Year"
            required
            error={errorMessages.start_year}
          />
        </div>
      </div>
      <div>
        <span className="text-sm font-semibold">End date (or expected)*</span>
        <div className="grid grid-cols-[2fr_1fr] gap-4">
          <SelectField
            control={control}
            {...register("end_month")}
            options={months().map((label, i) => ({
              label,
              value: `${i}`,
            }))}
            label=""
            setValue={setValue}
            placeholder="Month"
            searchByLabel
            required
            error={errorMessages.end_month}
          />
          <SelectField
            control={control}
            {...register("end_year")}
            options={getYearsOptions(true)}
            label=""
            setValue={setValue}
            placeholder="Year"
            required
            error={errorMessages.end_year}
          />
        </div>
      </div>
      <SelectField
        control={control}
        collisionPadding={200}
        options={degreeOptions}
        label="Education Level"
        setValue={setValue}
        placeholder="Example"
        required
        error={errorMessages.degree}
        {...register("degree")}
      />
      {degreeFormFields(register, control, setValue, errorMessages, degree)}
    </EditForm>
  );
};

export default Education;
