import { z } from "zod";
import { formSchema } from "~/components/forms/v2/jobseeker/Education/schema";
import axios from "~/services/axios.service";

export const createJobSeekerEducation = ({
  uid,
  data,
}: {
  uid: number;
  data: Partial<z.infer<typeof formSchema>>;
}) =>
  axios
    .post(`/api/jobseeker/education/${uid}`, data)
    .then(response => response.data)
    .catch(e => e.message);
