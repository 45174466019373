import axios from "~/services/axios.service";
import { seekerProfileHeader } from "~/types/jobseeker";

export const updateProfileHeader = ({
  uid,
  data,
}: {
  uid: number;
  data: Partial<seekerProfileHeader>;
}) =>
  axios
    .put(`/api/jobseeker/profile-header/${uid}`, data)
    .then(response => response.data)
    .catch(e => e.message);
