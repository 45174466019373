import { curricular } from "~/types/jobseeker";

export const formatFields = (fields?: curricular) => {
  if (!fields) return fields;

  const { start_month, start_year } = fields;

  return {
    ...fields,
    start_month: start_month ? String(start_month) : start_month,
    start_year: start_year ? String(start_year) : start_year,
  };
};
