import { objectType } from "../types/common";
import { validationsObject, validateReturnType } from "../types/validations";
import logger from "~/utils/logger";

const formValidate = function (
  formValues: objectType,
  validations: validationsObject,
): validateReturnType {
  let valid = true;
  const errors: objectType = {};

  try {
    if (!formValues || !validations) {
      throw new Error("Wrong inputs format");
    }

    Object.keys(validations).forEach((key) => {
      if (!validations[key] || !validations[key].length) {
        throw new Error(`Validation of "${key}" not found`);
      }

      validations[key].forEach(({ fn, error }) => {
        if (!fn) {
          throw new Error(`Function validate of "${key}" not found`);
        }
        if (!error) {
          throw new Error(`Error message of "${key}" not found`);
        }

        if (!fn(formValues[key], formValues)) {
          if (errors[key]) return;
          valid = false;
          errors[key] = error;
        }
      });
    });
  } catch (e) {
    valid = false;
    logger.error(`formValidate: ${e}`); // eslint-disable-line no-console
  }

  return { valid, errors };
};

export default formValidate;
