import { z } from "zod";
import { formSchema } from "~/components/forms/v2/jobseeker/VolunteerWork/schema";
import axios from "~/services/axios.service";

export const updateVolunteerWork = async ({
  uid,
  data,
}: {
  uid: number;
  data: Partial<z.infer<typeof formSchema> & { id: number }>;
}) => await axios.put(`/api/jobseeker/volunteer-work/${uid}`, data);
