export default function dataURLtoFile(
  dataURL: string,
  fileName: string
): File | null {
  const matches = dataURL.match(/^data:(.*);base64,(.*)$/);

  if (!matches || matches.length !== 3) {
    return null;
  }

  const [, mimeType, base64Data] = matches;
  const buffer = Buffer.from(base64Data, "base64");

  const file = new File([buffer], fileName, { type: mimeType });

  return file;
}
