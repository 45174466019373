import axios from "~/services/axios.service";
import { jobExperience } from "~/types/jobseeker";

export const updateJobExperience = ({
  uid,
  data,
}: {
  uid: number;
  data: Partial<jobExperience>;
}) =>
  axios
    .put(`/api/jobseeker/job-experience/${uid}`, data)
    .then(response => response.data)
    .catch(e => e.message);
