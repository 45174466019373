import { FC } from "react";
import { z } from "zod";
import { EditForm, RadioField } from "~/components/jobseeker/EditForm";
import { Checkbox } from "~/components/v2/Checkbox";
import { FormField } from "~/components/v2/Form";
import { useUpdateDemographics } from "~/hooks/jobseeker/useUpdateDemographics";
import useCustomForm from "~/hooks/useCustomForm";
import { jobSeekerBasicInfo, raceEthnicity } from "~/types/jobseeker";

interface DemographicsProps {
  data?: jobSeekerBasicInfo;
  onSubmit: () => void;
}

export const demographicsFormSchema = z.object({
  non_traditional: z
    .enum(["YES", "NO", "PREFER_NOT_TO_ANSWER"])
    .optional()
    .nullable(),
  race_ethinicity: z
    .array(
      z.enum([
        "white",
        "black_or_african_amercian",
        "native_hawaiian_or_pacific_islander",
        "native_american_or_alaska_native",
        "middle_eastern",
        "asian",
        "hispani_or_latino",
        "other_race",
        "prefer_not_to_answer",
      ])
    )
    .optional()
    .nullable(),
  veteran_status: z
    .enum(["YES", "NO", "PREFER_NOT_TO_ANSWER"])
    .optional()
    .nullable(),
  gender: z
    .enum([
      "MALE",
      "FEMALE",
      "NON__BINARY",
      "GENDER_NOT_LISTED",
      "PREFER_NOT_TO_ANSWER",
    ])
    .optional()
    .nullable(),
});

const genderOptions = [
  { value: "MALE", label: "Male" },
  { value: "FEMALE", label: "Female" },
  { value: "NON__BINARY", label: "Non-binary" },
  { value: "GENDER_NOT_LISTED", label: "Gender not listed" },
  { value: "PREFER_NOT_TO_ANSWER", label: "Prefer not to answer" },
];

const raceOptions = [
  { value: "white", label: "White" },
  { value: "black_or_african_amercian", label: "Black or African American" },
  {
    value: "native_hawaiian_or_pacific_islander",
    label: "Native Hawaiian or Pacific Islander",
  },
  {
    value: "native_american_or_alaska_native",
    label: "Native American or Alaska Native",
  },
  { value: "middle_eastern", label: "Middle Eastern" },
  { value: "asian", label: "Asian" },
  { value: "hispani_or_latino", label: "Hispanic or Latino/Latina/LatinX" },
  { value: "other_race", label: "Other race, ethnicity or origin" },
  { value: "prefer_not_to_answer", label: "Prefer not to answer" },
];

const Demographics: FC<DemographicsProps> = ({ data, onSubmit }) => {
  const { mutateAsync } = useUpdateDemographics();
  const { control, handleSubmit, submit, isDirty, reset, isValid, watch } =
    useCustomForm({
      update: (newData) => mutateAsync({ uid: data!.seekerId, data: newData }),
      formSchema: demographicsFormSchema,
      data,
      onSubmit,
    });

  const race = watch("race_ethinicity");

  return (
    <EditForm
      title="Demographics"
      subTitle="Edit"
      onSubmit={submit}
      submitHandler={handleSubmit}
      isDirty={isDirty}
      reset={reset}
      isValid={isValid}
      needsValidity
    >
      <RadioField
        control={control}
        label="Do you consider yourself someone with a non-traditional work history?"
        name="non_traditional"
        options={[
          { label: "Yes", value: "YES" },
          { label: "No", value: "NO" },
          { label: "Prefer not to answer", value: "PREFER_NOT_TO_ANSWER" },
        ]}
      />
      <div className="flex flex-col gap-1">
        <span className="text-sm font-semibold">Race / Ethnicity</span>
        <FormField
          control={control}
          name="race_ethinicity"
          render={({ field: { value = [], onChange } }) => (
            <div className="flex flex-col gap-2">
              {raceOptions.map((option) => (
                <div key={option.value} className="flex gap-2 items-center">
                  <Checkbox
                    id={option.value}
                    checked={value?.includes(option.value as raceEthnicity)}
                    className="border-[#CCCCCC] text-white data-[state=checked]:bg-[#000D3D] data-[state=checked]:border-[#000D3D]"
                    onCheckedChange={(checked) => {
                      if (checked) {
                        if (option.value === "prefer_not_to_answer") {
                          onChange([option.value]);
                          return;
                        }
                        onChange([...(value || []), option.value]);
                        return;
                      }
                      const copy = [...(value || [])];
                      const index = copy.indexOf(option.value as raceEthnicity);
                      copy.splice(index, 1);
                      onChange(copy);
                    }}
                    disabled={
                      value?.includes("prefer_not_to_answer") &&
                      option.value !== "prefer_not_to_answer"
                    }
                  />
                  <label htmlFor={option.value}>{option.label}</label>
                </div>
              ))}
            </div>
          )}
        />
      </div>
      <RadioField
        control={control}
        label="Veteran Status"
        name="veteran_status"
        options={[
          { label: "Yes", value: "YES" },
          { label: "No", value: "NO" },
          { label: "Prefer not to answer", value: "PREFER_NOT_TO_ANSWER" },
        ]}
      />
      <RadioField
        control={control}
        label="Gender"
        name="gender"
        options={genderOptions}
      />
    </EditForm>
  );
};

export default Demographics;
