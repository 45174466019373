import {
  Educations,
  JobExperiences,
  SeekerProfileHeader,
} from "@prisma/client";
import { FC, useEffect } from "react";
import { z } from "zod";
import {
  EditForm,
  Section,
  SelectField,
  TextAreaField,
  TextField,
} from "~/components/jobseeker/EditForm";
import { LocationField } from "~/components/jobseeker/EditForm/LocationField";
import useJobAndEducation, {
  JobSeekerAccount,
} from "~/hooks/jobseeker/useJobAndEducation";
import { useUpdateProfileHeader } from "~/hooks/jobseeker/useUpdateProfileHeader";
import useAddress from "~/hooks/useAddress";
import useCustomForm from "~/hooks/useCustomForm";
import { education, jobExperience, jobSeekerAccount } from "~/types/jobseeker";

interface AboutProps {
  data?: jobSeekerAccount;
  onSubmit: () => void;
  educations?: education[] | null;
  jobExperiences?: jobExperience[] | null;
}

const formSchema = z.object({
  first_name: z.string().min(1, "Please enter your first name"),
  last_name: z.string().min(1, "Please enter your last name"),
  job_title: z.string().optional().nullable(),
  education: z.string().optional().nullable(),
  address_line_1: z.string().min(1, "Please enter your address"),
  address_line_2: z.string().optional(),
  city: z.string().min(1, "Please enter your city"),
  state: z.string().min(1, "Please enter your state"),
  zipcode: z.string().min(1, "Please enter your zip code"),
  country: z.string().min(1, "Please enter your country"),
  headline: z.string().optional().nullable(),
});

const About: FC<AboutProps> = ({
  data,
  onSubmit,
  educations = [],
  jobExperiences = [],
}) => {
  const sanitizedEducations = educations ?? [];
  const sanitizedExperiences = jobExperiences ?? [];
  const { mutateAsync } = useUpdateProfileHeader();

  const formData = {
    id: data?.seeker_profile_header?.seekerId!,
    first_name: data?.seeker_profile_header?.first_name,
    last_name: data?.seeker_profile_header?.last_name,
    job_title: data?.seeker_profile_header?.job_title,
    education: data?.seeker_profile_header?.education,
    headline: data?.seeker_profile_header?.headline,

    address_line_1: data?.job_seeker_basicinfo?.address_line_1,
    address_line_2: data?.job_seeker_basicinfo?.address_line_2,
    city: data?.job_seeker_basicinfo?.city,
    state: data?.job_seeker_basicinfo?.state,
    zipcode: data?.job_seeker_basicinfo?.zipcode,
    country: data?.job_seeker_basicinfo?.country,
  };

  const {
    register,
    control,
    setValue,
    errorMessages,
    handleSubmit,
    submit,
    isDirty,
    reset,
    isValid,
  } = useCustomForm({
    formSchema,
    data: formData,
    update: newData =>
      mutateAsync({
        uid: data?.id!,
        data: newData,
      }),
    onSubmit,
  });

  const builtData = {} as JobSeekerAccount;
  builtData.seeker_profile_header =
    data?.seeker_profile_header as SeekerProfileHeader;
  builtData.educations = sanitizedEducations as Educations[];
  builtData.jobExpericences = jobExperiences as JobExperiences[];

  const { lastEducation, lastJob } = useJobAndEducation(builtData);

  if (!data?.seeker_profile_header?.education && lastEducation) {
    setValue("education", lastEducation);
  }
  if (!data?.seeker_profile_header?.job_title && lastJob) {
    setValue("job_title", lastJob);
  }

  const { address, setPlaceId } = useAddress();

  useEffect(() => {
    if (address) {
      setValue("address_line_1", address.address_line_1, {
        shouldValidate: true,
      });
      setValue("city", address.city, { shouldValidate: true });
      setValue("state", address.state, { shouldValidate: true });
      setValue("zipcode", address.postalCode, { shouldValidate: true });
      setValue("country", address.country, { shouldValidate: true });
    }
  }, [address]);

  return (
    <EditForm
      title="About"
      subTitle="Edit"
      onSubmit={submit}
      submitHandler={handleSubmit}
      isDirty={isDirty}
      reset={reset}
      isValid={isValid}
      needsValidity
    >
      <div className="flex flex-col gap-8">
        <Section title="Name">
          <TextField
            label="First name"
            {...register("first_name")}
            error={errorMessages.first_name}
            required
          />
          <TextField
            label="Last name"
            {...register("last_name")}
            error={errorMessages.last_name}
            required
          />
        </Section>
        <Section title="Current position">
          <SelectField
            label="Job title / current position"
            {...register("job_title")}
            error={errorMessages.job_title}
            control={control}
            setValue={setValue}
            options={sanitizedExperiences.map(({ job_title, employer }) => ({
              label: `${job_title} at ${employer}`,
              value: `${job_title} at ${employer}`,
            }))}
          />
        </Section>
        <Section title="Education">
          <SelectField
            label="Education"
            {...register("education")}
            control={control}
            setValue={setValue}
            error={errorMessages.education}
            options={sanitizedEducations.map(
              ({ school_training_institute }) => ({
                label: school_training_institute,
                value: school_training_institute,
              }),
            )}
          />
        </Section>
        <Section title="Location">
          <LocationField
            control={control}
            required
            setValue={(_, data) => setPlaceId(data.place_id)}
            label="Address line 1"
            placeholder={"ex: 123 Main Street"}
            labelHelp={`
            SCALIS uses your address to help our algorithm match you
            to the best jobs. Employers will only see your town/city,
            state, and postal code.
            `}
            {...register("address_line_1")}
            error={errorMessages.address_line_1}
            types={[]}
          />
          <TextField
            {...register("address_line_2")}
            placeholder="Ex: Loft 123"
            label="Address line 2 (optional)"
            error={errorMessages.address_line_2}
          />
          <TextField
            label="City"
            {...register("city")}
            error={errorMessages.city}
            required
          />
          <TextField
            label="State"
            {...register("state")}
            error={errorMessages.state}
            required
          />
          <TextField
            {...register("zipcode")}
            placeholder=" "
            label="Postal code"
            error={errorMessages.zipcode}
            required
          />
          <TextField
            {...register("country")}
            placeholder=" "
            label="Country"
            error={errorMessages.country}
            required
          />
        </Section>

        <Section title="About">
          <TextAreaField
            {...register("headline")}
            subText="Write something about you"
            label="Headline"
          />
        </Section>
      </div>
    </EditForm>
  );
};

export default About;
