import { format } from "date-fns";
import { FC } from "react";
import VolunteerWorkForm from "~/components/forms/v2/jobseeker/VolunteerWork";
import { curricular, JobType } from "~/types/jobseeker";
import { Card, CardTitle } from "../v2/Card";
import TruncatedText from "../v2/TruncatedText";
import { DefaultProps } from "./DefaultProps";
import LoadingItem from "./LoadingItem";

const VolunteerWork: FC<DefaultProps> = ({
  data,
  loading,
  refetch,
  isEdition,
}) => {
  return (
    <Card className="gap-2 p-4">
      <CardTitle className="text-base">
        Volunteer Work
        {isEdition && <VolunteerWorkForm onSubmit={refetch} uid={data?.id} />}
      </CardTitle>
      {loading ? (
        <LoadingItem />
      ) : (
        data?.curricular?.map(work => (
          <VolunteerWorkItem
            key={work.id}
            refetch={refetch}
            data={work}
            isEdition={isEdition}
          />
        ))
      )}
    </Card>
  );
};

interface VolunteerWorkItemProps {
  data: curricular;
  refetch: () => void;
  isEdition: boolean;
}

const jobTypeMap: { [key in JobType]: string } = {
  FULL_TIME: "Full-time",
  PART_TIME: "Part-time",
  SEASONAL: "Seasonal",
  CONTRACTOR: "Contractor",
  INTERNSHIP: "Internship",
  PER_DIEM: "Per diem",
  TEMPORARY: "Temporary",
  OTHER: "Other",
};

const VolunteerWorkItem: FC<VolunteerWorkItemProps> = ({
  data,
  refetch,
  isEdition,
}) => {
  return (
    <div className="flex flex-col after:my-4 after:self-stretch after:border-b after:border-[#ccc] after:content-[''] last:after:m-0 last:after:border-0">
      <CardTitle className="text-base font-semibold">
        <span>{data.job_title}</span>
        {isEdition && (
          <VolunteerWorkForm
            uid={data?.seekerId}
            onSubmit={refetch}
            data={data}
          />
        )}
      </CardTitle>
      <div className="flex flex-col py-0">
        <CardTitle className="text-sm font-normal">
          {data.company_institute_name}
          {data.job_type && ` - ${jobTypeMap[data.job_type]}`}
        </CardTitle>
        <span className="text-xs font-light italic text-[#595959] lg:text-sm">
          {format(new Date(data.start_year, data.start_month, 1), "LLL yyyy")} -{" "}
          {data.is_currently_working === "YES" ||
          data.end_month === undefined ||
          data.end_month === null ||
          !data.end_year
            ? "Present"
            : `${format(
                new Date(data.end_year, data.end_month, 1),
                "LLL yyyy",
              )}`}
        </span>
        <span className="text-xs font-light italic text-[#595959] lg:text-sm">
          {data.location}
        </span>
      </div>
      <TruncatedText text={data.job_description} />
    </div>
  );
};

export default VolunteerWork;
