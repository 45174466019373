const Facebook = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M11.1024 8.83331L11.4727 6.42029H9.15732V4.85441C9.15732 4.19425 9.48076 3.55076 10.5177 3.55076H11.5703V1.49633C11.5703 1.49633 10.6151 1.33331 9.70186 1.33331C7.79508 1.33331 6.54873 2.48904 6.54873 4.58123V6.42029H4.4292V8.83331H6.54873V14.6666H9.15732V8.83331H11.1024Z"
      fill="currentColor"
    />
  </svg>
);

export default Facebook;
