export const degreeOptions = [
  { value: "HIGH_SCHOOL", label: "High School" },
  { value: "ASSOCIATES", label: "Associates" },
  { value: "CERTIFICATE", label: "Certificate" },
  { value: "ADVANCED_CERTIFICATE", label: "Advanced Certificate" },
  { value: "BACHELORS", label: "Bachelors" },
  { value: "MASTERS", label: "Masters" },
  { value: "DOCTORATE", label: "Doctorate" },
  { value: "POSTDOCTORAL_STUDIES", label: "Postdoctoral Studies" },
  { value: "NON__DEGREE_SEEKING", label: "Non-Degree Seeking" },
  { value: "TECHNICAL_DIPLOMA", label: "Technical Diploma" },
];

export const GPAScaleOptions = [
  { label: "4.0", value: "4" },
  { label: "5.0", value: "5" },
  { label: "6.0", value: "6" },
  { label: "8.0", value: "8" },
  { label: "9.0", value: "9" },
  { label: "10.0", value: "10" },
  { label: "11.0", value: "11" },
  { label: "12.0", value: "12" },
  { label: "13.0", value: "13" },
  { label: "14.0", value: "14" },
  { label: "15.0", value: "15" },
  { label: "16.0", value: "16" },
  { label: "17.0", value: "17" },
  { label: "18.0", value: "18" },
  { label: "19.0", value: "19" },
  { label: "20.0", value: "20" },
  { label: "100.0", value: "100" },
];
