import { zodResolver } from "@hookform/resolvers/zod";
import { AxiosResponse } from "axios";
import { useEffect } from "react";
import { UseFormSetValue, useForm } from "react-hook-form";
import { z } from "zod";

interface CustomFormParams<T> {
  data?: T & { id: number };
  update?: (data: z.infer<z.ZodType>) => Promise<void> | Promise<AxiosResponse>;
  create?: (data: z.infer<z.ZodType>) => Promise<void> | Promise<AxiosResponse>;
  deleteItem?: (id: number) => Promise<void> | Promise<AxiosResponse>;
  formSchema: z.ZodType;
  onSubmit?: () => void;
  onReset?: () => void;
  controlledDefaults?: Partial<z.infer<z.ZodType>>;
}

const useCustomForm = <T,>({
  data,
  update,
  create,
  deleteItem,
  formSchema,
  onSubmit,
  controlledDefaults,
  onReset,
}: CustomFormParams<T>) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmitSuccessful },
    control,
    setValue,
    watch,
    trigger,
    setFocus,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: "all",
    values: data,
    defaultValues: controlledDefaults,
  });

  const handleReset = () => {
    reset();
    if (onReset) {
      onReset();
    }
  };

  const submit = (newData: z.infer<typeof formSchema>) => {
    if (data && !!update) {
      return update({ ...newData, id: data.id })
        .then(() => {
          if (onSubmit) onSubmit();
          handleReset();
        })
        .catch();
    } else {
      if (!create) return Promise.resolve();
      return create(newData)
        .then(() => {
          if (onSubmit) onSubmit();
          handleReset();
        })
        .catch();
    }
  };

  const handleDelete = () => {
    if (!data?.id || !deleteItem) return;

    deleteItem(data.id)
      .then(() => {
        if (onSubmit) onSubmit();
        handleReset();
      })
      .catch();
  };

  const errorMessages = (() => {
    const messages: { [key in keyof typeof errors]: string | undefined } = {};
    Object.entries(errors).forEach(([key, value]) => {
      messages[key] = value?.message as string | undefined;
    });
    return messages;
  })();

  const customSetValue: UseFormSetValue<any> = (name, value, opts, ...rest) =>
    setValue(name, value, { shouldDirty: true, ...opts }, ...rest);

  return {
    register,
    reset,
    handleSubmit,
    submit,
    handleDelete,
    errorMessages,
    isDirty,
    control,
    setValue: customSetValue,
    watch,
    isValid,
    trigger,
    setFocus,
  };
};

export default useCustomForm;
