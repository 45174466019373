import axios from "~/services/axios.service";

export const deleteJobSeekerEducation = ({
  uid,
  id,
}: {
  uid: number;
  id: number;
}) =>
  axios
    .delete(`/api/jobseeker/education/${uid}/${id}`)
    .then(response => response.data)
    .catch(e => e.message);
