import { FC } from "react";
import AchievementForm from "~/components/forms/v2/jobseeker/Achievement";
import { achievement } from "~/types/jobseeker";
import { Card, CardTitle } from "../v2/Card";
import TruncatedText from "../v2/TruncatedText";
import { DefaultProps } from "./DefaultProps";
import LoadingItem from "./LoadingItem";

const Achievements: FC<DefaultProps> = ({
  data,
  loading,
  refetch,
  isEdition,
}) => {
  return (
    <Card className="gap-2 p-4">
      <CardTitle className="text-base">
        Achievements
        {isEdition && <AchievementForm uid={data?.id} onSubmit={refetch} />}
      </CardTitle>
      {loading ? (
        <LoadingItem />
      ) : (
        data?.achievements?.map(achievement => (
          <AchievementsItem
            key={achievement.id}
            data={achievement}
            refetch={refetch}
            isEdition={isEdition}
          />
        ))
      )}
    </Card>
  );
};

interface AchievementsItemProps {
  data: achievement;
  refetch: () => void;
  isEdition: boolean;
}

const AchievementsItem: FC<AchievementsItemProps> = ({
  data,
  refetch,
  isEdition,
}) => {
  return (
    <div className="flex flex-col after:my-4 after:self-stretch after:border-b after:border-[#ccc] after:content-[''] last:after:m-0 last:after:border-0">
      <CardTitle className="text-base font-semibold">
        {data.award_name}{" "}
        {isEdition && (
          <AchievementForm
            uid={data?.seekerId}
            data={data}
            onSubmit={refetch}
          />
        )}
      </CardTitle>
      <div className="flex flex-col py-0">
        <CardTitle className="text-sm font-normal">
          {data.issuing_authority}
        </CardTitle>
        <TruncatedText
          className="text-sm text-[#595959]"
          text={data.description}
        />
      </div>
    </div>
  );
};

export default Achievements;
