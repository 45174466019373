import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { updateVolunteerWork } from "~/mutations/jobseeker/updateVolunteerWork";
import { errorHandler } from "~/utils/error";
import { toast } from "../useToast";

export const useUpdateVolunteerWork = () =>
  useMutation({
    mutationFn: updateVolunteerWork,
    ...{
      onError: e => {
        errorHandler(toast, e as AxiosError<any>);
      },
    },
  });
