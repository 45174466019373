import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "~/services/axios.service";
import { jobSeekerAccount } from "~/types/jobseeker";

export const fetchInfo = (
  { signal }: QueryFunctionContext,
  uid?: number,
): Promise<jobSeekerAccount | undefined> =>
  axios
    .get(`/api/jobseeker/information/${uid}`, {
      signal,
    })
    .then(({ data }) => data.information);
