import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { deleteVolunteerWork } from "~/mutations/jobseeker/deleteVolunteerWork";
import { errorHandler } from "~/utils/error";
import { toast } from "../useToast";

export const useDeleteVolunteerWork = () =>
  useMutation({
    mutationFn: deleteVolunteerWork,
    ...{ onError: e => errorHandler(toast, e as AxiosError<any>) },
  });
