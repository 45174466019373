import { FC } from "react";
import { z } from "zod";
import { EditForm, TextField } from "~/components/jobseeker/EditForm";
import { useUpdateSocialMedia } from "~/hooks/jobseeker/useUpdateSocialMedia";
import useCustomForm from "~/hooks/useCustomForm";
import Add from "~/icons/Add";
import { jobSeekerBasicInfo } from "~/types/jobseeker";

interface SocialMediaProps {
  data?: jobSeekerBasicInfo;
  onSubmit: () => void;
}

export const socialMediaFormSchema = z.object({
  linkedin_link: z
    .string()
    .regex(
      /(^$)|(https?:\/\/(www.)?linkedin.com\/in\/.+)/,
      "Invalid LinkedIn profile URL"
    )
    .optional()
    .nullable(),
  twitter_link: z
    .string()
    .regex(
      /(^$)|(https?:\/\/(www.)?((twitter)|x).com\/.+)/,
      "Invalid Twitter / X profile URL"
    )
    .optional()
    .nullable(),
  facebook_link: z
    .string()
    .regex(
      /(^$)|(https?:\/\/(www.)?((facebook)|(fb)).com\/.+)/,
      "Invalid Facebook profile URL"
    )
    .optional()
    .nullable(),
  website_link: z
    .string()
    .regex(
      /(^$)|(https?:\/\/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?)/,
      "Invalid URL"
    )
    .optional()
    .nullable(),
  website_b_link: z
    .string()
    .regex(
      /(^$)|(https?:\/\/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?)/,
      "Invalid URL"
    )
    .optional()
    .nullable(),
});

const SocialMedia: FC<SocialMediaProps> = ({ data, onSubmit }) => {
  const { mutateAsync } = useUpdateSocialMedia();
  const {
    register,
    handleSubmit,
    errorMessages,
    submit,
    isDirty,
    reset,
    setValue,
    isValid,
  } = useCustomForm({
    update: (newData) => mutateAsync({ uid: data!.seekerId, data: newData }),
    formSchema: socialMediaFormSchema,
    data,
    onSubmit,
  });

  const handleLink = (value: string, fieldName: string) => {
    const hasProtocol = value.startsWith("http://") || value.startsWith("https://");
    const hasWWW = value.startsWith("www.");
    const isEmpty = value === "";
    if(isEmpty) {
      setValue(fieldName, value);
    }
    if(hasProtocol) {
      setValue(fieldName, value);
    }
    if(value.startsWith("www.")) {
      value = `https://${value}`;
      setValue(fieldName, value);
    }
    if(!hasWWW && !isEmpty && !hasProtocol) {
      value = `https://www.${value}`;
      setValue(fieldName, value);
    }
  }

  return (
    <EditForm
      title="Websites and social media"
      subTitle="Edit"
      onSubmit={submit}
      submitHandler={handleSubmit}
      isDirty={isDirty}
      reset={reset}
      isValid={isValid}
      iconTrigger={<Add />}
      needsValidity
    >
      <TextField
        label="LinkedIn Profile Link"
        error={errorMessages.linkedin_link}
        {...register("linkedin_link")}
        onChange={(e) => handleLink(e.target.value, "linkedin_link")}
      />
      <TextField
        label="Twitter Profile Link"
        error={errorMessages.twitter_link}
        {...register("twitter_link")}
        onChange={(e) => handleLink(e.target.value, "twitter_link")}
      />
      <TextField
        label="Facebook Profile Link"
        error={errorMessages.facebook_link}
        {...register("facebook_link")}
        onChange={(e) => handleLink(e.target.value, "facebook_link")}
      />
      <TextField
        label="Website Link 1"
        error={errorMessages.website_link}
        {...register("website_link")}
        onChange={(e) => handleLink(e.target.value, "website_link")}
      />
      <TextField
        label="Website Link 2"
        error={errorMessages.website_b_link}
        {...register("website_b_link")}
        onChange={(e) => handleLink(e.target.value, "website_b_link")}
      />
    </EditForm>
  );
};

export default SocialMedia;
