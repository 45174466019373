const LinkedIn = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M4.3176 14.6666H1.55325V5.76466H4.3176V14.6666ZM2.93394 4.55035C2.04999 4.55035 1.33301 3.81819 1.33301 2.93424C1.33301 2.50965 1.50168 2.10245 1.80191 1.80221C2.10214 1.50198 2.50934 1.33331 2.93394 1.33331C3.35853 1.33331 3.76573 1.50198 4.06596 1.80221C4.3662 2.10245 4.53487 2.50965 4.53487 2.93424C4.53487 3.81819 3.81759 4.55035 2.93394 4.55035ZM14.6637 14.6666H11.9053V10.3332C11.9053 9.30046 11.8844 7.97602 10.468 7.97602C9.0308 7.97602 8.81056 9.09807 8.81056 10.2588V14.6666H6.04918V5.76466H8.70043V6.97898H8.73913C9.10818 6.27955 10.0097 5.54144 11.3547 5.54144C14.1523 5.54144 14.6666 7.38375 14.6666 9.77666V14.6666H14.6637Z"
      fill="currentColor"
    />
  </svg>
);

export default LinkedIn;
