const Globe = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M10.5002 7.99998C10.5002 8.5781 10.4689 9.1354 10.4142 9.66665H5.5861C5.52881 9.1354 5.50016 8.5781 5.50016 7.99998C5.50016 7.42185 5.53141 6.86456 5.5861 6.33331H10.4142C10.4715 6.86456 10.5002 7.42185 10.5002 7.99998ZM11.2502 6.33331H14.4559C14.5939 6.86717 14.6668 7.42446 14.6668 7.99998C14.6668 8.5755 14.5939 9.13279 14.4559 9.66665H11.2502C11.3049 9.13019 11.3335 8.5729 11.3335 7.99998C11.3335 7.42706 11.3049 6.86977 11.2502 6.33331ZM14.1825 5.49998H11.1434C10.883 3.83592 10.3674 2.44269 9.70329 1.55206C11.7424 2.09113 13.4012 3.57029 14.1799 5.49998H14.1825ZM10.2996 5.49998H5.70068C5.85954 4.55206 6.10433 3.71352 6.40381 3.03383C6.67725 2.41925 6.98193 1.97394 7.2762 1.69269C7.56787 1.41665 7.81006 1.33331 8.00016 1.33331C8.19027 1.33331 8.43245 1.41665 8.72412 1.69269C9.01839 1.97394 9.32308 2.41925 9.59652 3.03383C9.8986 3.71092 10.1408 4.54946 10.2996 5.49998ZM4.85693 5.49998H1.81787C2.59912 3.57029 4.25537 2.09113 6.29704 1.55206C5.63298 2.44269 5.11735 3.83592 4.85693 5.49998ZM1.54443 6.33331H4.75016C4.69548 6.86977 4.66683 7.42706 4.66683 7.99998C4.66683 8.5729 4.69548 9.13019 4.75016 9.66665H1.54443C1.40641 9.13279 1.3335 8.5755 1.3335 7.99998C1.3335 7.42446 1.40641 6.86717 1.54443 6.33331ZM6.40381 12.9635C6.10173 12.2864 5.85954 11.4479 5.70068 10.5H10.2996C10.1408 11.4479 9.896 12.2864 9.59652 12.9635C9.32308 13.5781 9.01839 14.0234 8.72412 14.3047C8.43245 14.5833 8.19027 14.6666 8.00016 14.6666C7.81006 14.6666 7.56787 14.5833 7.2762 14.3073C6.98193 14.026 6.67725 13.5807 6.40381 12.9661V12.9635ZM4.85693 10.5C5.11735 12.164 5.63298 13.5573 6.29704 14.4479C4.25537 13.9088 2.59912 12.4297 1.81787 10.5H4.85693ZM14.1825 10.5C13.4012 12.4297 11.745 13.9088 9.70589 14.4479C10.37 13.5573 10.883 12.164 11.146 10.5H14.1825Z"
      fill="currentColor"
    />
  </svg>
);

export default Globe;
