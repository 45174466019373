import { z } from "zod";
import { validateNumber } from "~/utils/validateNumber";

export const formSchema = z
  .object({
    employer: z.string().nonempty("Please enter the employer"),
    is_currently_working: z
      .string()
      .nonempty("Required")
      .pipe(
        z.enum(["YES", "NO"], {
          invalid_type_error: "Required",
        }),
      ),
    job_title: z.string().nonempty("Please enter the job title"),
    s_job_description: z
      .string()
      .nonempty("Please enter a short job description"),
    l_job_description: z.string().optional(),
    start_year: z.coerce.number().refine(value => value != null, {
      message: "Please select a starting year",
    }),
    end_year: z.preprocess(validateNumber, z.number().optional()).optional(),
    start_month: z.coerce.number().refine(value => value != null, {
      message: "Please select starting month",
    }),

    end_month: z.preprocess(validateNumber, z.number().optional()).optional(),
    management_position: z
      .string()
      .nonempty("Please inform if it is / was a management position")
      .pipe(
        z.enum(["YES", "NO"], {
          required_error: "Please inform if it is / was a management position",
          invalid_type_error:
            "Please inform if it is / was a management position",
        }),
      ),
    remote_or_inperson: z
      .string()
      .nonempty("Please select location type")
      .pipe(
        z.enum(["IN_PERSON", "REMOTE", "HYBRID"], {
          required_error: "Please select location type",
          invalid_type_error: "Please select location type",
        }),
      ),
    how_large_team_min: z
      .number({
        required_error: "Please enter how large is / was the team",
        invalid_type_error: "Please enter how large is / was the team",
      })
      .nullable(),
    how_large_team_max: z.number().nullable(),
    field_industry: z.string().nonempty("Please enter industry"),
    field_sub_industry: z.string().nonempty("Please enter sub-industry"),
    job_type: z
      .string()
      .nonempty("Please select job type")
      .pipe(
        z.enum(
          [
            "FULL_TIME",
            "PART_TIME",
            "SEASONAL",
            "CONTRACTOR",
            "INTERNSHIP",
            "PER_DIEM",
            "TEMPORARY",
            "OTHER",
          ],
          {
            errorMap: () => ({ message: "Please select job type" }),
          },
        ),
      ),
    location: z.string().nonempty("Please enter city, state and country"),
    hourly_salary: z.enum(["HOURLY", "SALARY", ""]).optional().nullable(),
    salary: z.coerce.number().optional().nullable(),
    plus_comission: z.boolean().optional().nullable(),
  })
  .superRefine(({ end_month, end_year, is_currently_working }, context) => {
    if (is_currently_working === "NO" && !end_month && end_month !== 0) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Please enter ending month",
        path: ["end_month"],
      });
    }
    if (is_currently_working === "NO" && !end_year) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Please enter ending year",
        path: ["end_year"],
      });
    }
  });
