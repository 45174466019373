import { FC } from "react";
import { cn } from "~/utils/cn";
import AboutForm from "../forms/v2/jobseeker/About";
import { Card, CardTitle } from "../v2/Card";
import { Skeleton } from "../v2/Skeleton";
import TruncatedText from "../v2/TruncatedText";
import { DefaultProps } from "./DefaultProps";

const About: FC<DefaultProps> = ({ data, loading, refetch, isEdition }) => {
  if (loading) {
    return (
      <Card>
        <CardTitle className="text-base">About</CardTitle>
        {new Array(3).fill(1).map((_, i) => (
          <Skeleton
            key={i}
            className={cn(
              "mb-1 h-3",
              i % 2 ? "w-52" : "w-48",
              i % 2 ? "md:w-96" : "md:w-80",
            )}
          />
        ))}
      </Card>
    );
  }

  return (
    <Card className="gap-2 p-4">
      <CardTitle className="text-base">
        About
        {isEdition && (
          <AboutForm
            data={data}
            educations={data?.educations}
            jobExperiences={data?.jobExpericences}
            onSubmit={refetch}
          />
        )}
      </CardTitle>
      {data?.seeker_profile_header?.headline ? (
        <TruncatedText text={data?.seeker_profile_header?.headline} />
      ) : (
        <span className="text-xs italic font-light text-typography-low-contrast-text lg:text-sm">
          Write something about you
        </span>
      )}
    </Card>
  );
};

export default About;
