import { Control, SetFieldValue, UseFormRegister } from "react-hook-form";
import {
  CheckboxField,
  NumberField,
  SelectField,
  TagField,
} from "~/components/jobseeker/EditForm";
import { FormField } from "~/components/v2/Form";
import { GPAScaleOptions } from "./utils";
import { DegreeLevel } from "@prisma/client";

export const degreeFormFields = (
  register: UseFormRegister<any>,
  control: Control,
  setValue: SetFieldValue<any>,
  errorMessages: { [key: string]: string | undefined },
  degree?: DegreeLevel | null,
) => {
  switch (degree) {
    case "HIGH_SCHOOL":
      return (
        <>
          <FormField
            control={control}
            {...register("cumulative_gpa")}
            render={({ field: { onChange, name, value } }) => (
              <NumberField
                name={name}
                value={value}
                onValueChange={(values) =>
                  onChange({ target: { name, value: values.floatValue || 0 } })
                }
                label="Cumulative GPA"
                error={errorMessages.cumulative_gpa}
              />
            )}
          />
          <CheckboxField
            label="Hide from employers"
            control={control}
            name="cumul_gpa_hide"
          />
          <SelectField
            label="GPA Scale"
            control={control}
            setValue={setValue}
            {...register("cumulative_gpa_scale")}
            options={GPAScaleOptions}
            error={errorMessages.cumulative_gpa_scale}
          />
        </>
      );
    case "ASSOCIATES":
    case "MASTERS":
      return (
        <>
          <TagField
            label="Area(s) of Study"
            control={control}
            {...register("area_of_study")}
            error={errorMessages.area_of_study}
          />
          <FormField
            control={control}
            {...register("cumulative_gpa")}
            render={({ field: { onChange, name, value } }) => (
              <NumberField
                name={name}
                value={value}
                onValueChange={(values) =>
                  onChange({ target: { name, value: values.floatValue || 0 } })
                }
                label="Cumulative GPA"
                error={errorMessages.cumulative_gpa}
              />
            )}
          />
          <CheckboxField
            label="Hide from employers"
            control={control}
            name="cumul_gpa_hide"
          />
          <SelectField
            label="GPA Scale"
            control={control}
            setValue={setValue}
            {...register("cumulative_gpa_scale")}
            options={GPAScaleOptions}
            error={errorMessages.cumulative_gpa_scale}
          />
        </>
      );
    case "BACHELORS":
      return (
        <>
          <TagField
            label="Major(s)"
            control={control}
            {...register("major")}
            error={errorMessages.major}
          />
          <TagField
            label="Minor(s)"
            control={control}
            {...register("minor")}
            error={errorMessages.minor}
          />
          <FormField
            control={control}
            {...register("cumulative_gpa")}
            render={({ field: { onChange, name, value } }) => (
              <NumberField
                name={name}
                value={value}
                onValueChange={(values) =>
                  onChange({ target: { name, value: values.floatValue || 0 } })
                }
                label="Cumulative GPA"
                error={errorMessages.cumulative_gpa}
              />
            )}
          />
          <CheckboxField
            label="Hide from employers"
            control={control}
            name="cumul_gpa_hide"
          />
          <SelectField
            label="GPA Scale"
            control={control}
            setValue={setValue}
            {...register("cumulative_gpa_scale")}
            options={GPAScaleOptions}
            error={errorMessages.cumulative_gpa_scale}
          />
          <FormField
            control={control}
            {...register("department_gpa")}
            render={({ field: { onChange, name, value } }) => (
              <NumberField
                name={name}
                value={value}
                onValueChange={(values) =>
                  onChange({ target: { name, value: values.floatValue || 0 } })
                }
                label="Department GPA"
                error={errorMessages.department_gpa}
              />
            )}
          />
          <CheckboxField
            label="Hide from employers"
            control={control}
            name="dept_gpa_hide"
          />
          <SelectField
            label="GPA Scale"
            control={control}
            setValue={setValue}
            {...register("department_gpa_scale")}
            options={GPAScaleOptions}
            error={errorMessages.department_gpa_scale}
          />
        </>
      );
    case "CERTIFICATE":
    case "ADVANCED_CERTIFICATE":
    case "DOCTORATE":
    case "POSTDOCTORAL_STUDIES":
    case "NON__DEGREE_SEEKING":
    case "TECHNICAL_DIPLOMA":
      return (
        <TagField
          label="Area(s) of Study"
          control={control}
          {...register("area_of_study")}
          error={errorMessages.area_of_study}
        />
      );
    default:
      return null;
  }
};
