const allowedExtensions: { [key: string]: string[] } = {
  pdf: ["application/pdf"],
  image: ["image/png", "image/jpg", "image/jpeg"],
};

export default function validateFile(
  file: any,
  allowedExtension: string,
): boolean {
  // Max size 10mb
  const maxSizeInBytes = 10485760;
  const allowed = allowedExtensions[allowedExtension].map((ext) =>
    file.type.includes(ext),
  );

  if (file && allowed && file.size <= maxSizeInBytes) {
    return true;
  }

  return false;
}
