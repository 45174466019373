import { FC } from "react";
import { skill } from "~/types/jobseeker";
import { cn } from "~/utils/cn";
import SkillForm from "../forms/v2/jobseeker/SkillPreliminary";
import { Card, CardTitle } from "../v2/Card";
import { Skeleton } from "../v2/Skeleton";
import { DefaultProps } from "./DefaultProps";

const Skills: FC<DefaultProps> = ({ data, loading, refetch, isEdition }) => {
  if (loading)
    return (
      <Card>
        <CardTitle>Skills and Expertise</CardTitle>
        {new Array(3).fill(1).map((_, i) => (
          <Skeleton
            key={i}
            className={cn(
              "mb-1 h-3",
              i % 2 ? "w-52" : "w-48",
              i % 2 ? "md:w-96" : "md:w-80",
            )}
          />
        ))}
      </Card>
    );

  return (
    <Card className="gap-2 p-4">
      <CardTitle className="text-base">
        Skills and Expertise
        {isEdition && (
          <SkillForm uid={data?.id} data={data?.skills} onSubmit={refetch} />
        )}
      </CardTitle>
      {data?.skills?.map(skill => <SkillItem key={skill.id} data={skill} />)}
    </Card>
  );
};

interface SkillItemProps {
  data: skill;
}

const SkillItem: FC<SkillItemProps> = ({ data }) => {
  return (
    <div className="flex justify-between">
      <div className="flex items-end gap-2">
        <span className="text-sm font-bold text-[#000D3D]">
          {data.skill_name}
        </span>
        <span className="text-xs text-[#595959]">
          <span className="text-xs capitalize">
            {data.skill_level?.toLowerCase()}
          </span>{" "}
          • {data.year_of_experience} years
        </span>
      </div>
    </div>
  );
};

export default Skills;
