import { z } from "zod";

export const formSchema = z.object({
  school_training_institute: z
    .string()
    .nonempty("Please enter institution name"),
  currently_studying: z
    .string()
    .nonempty("Required")
    .pipe(z.enum(["YES", "NO"])),
  degree: z
    .string()
    .nonempty("Please select an education level")
    .pipe(
      z.enum(
        [
          "HIGH_SCHOOL",
          "ASSOCIATES",
          "CERTIFICATE",
          "ADVANCED_CERTIFICATE",
          "BACHELORS",
          "MASTERS",
          "DOCTORATE",
          "POSTDOCTORAL_STUDIES",
          "NON__DEGREE_SEEKING",
          "TECHNICAL_DIPLOMA",
        ],
        {
          required_error: "Please select an education level",
          invalid_type_error: "Please select an education level",
        },
      ),
    ),
  major: z.array(z.string()).optional().nullable(),
  minor: z.array(z.string()).optional().nullable(),
  department_gpa: z.number().optional().nullable(),
  cumulative_gpa: z.preprocess(Number, z.number()).optional().nullable(),
  dept_gpa_hide: z.boolean().optional().nullable(),
  cumul_gpa_hide: z.boolean().optional().nullable(),
  cumulative_gpa_scale: z.preprocess(Number, z.number()).optional().nullable(),
  department_gpa_scale: z.preprocess(Number, z.number()).optional().nullable(),
  area_of_study: z.array(z.string()).optional().nullable(),
  start_year: z.coerce.number().refine(value => value != null, {
    message: "Please select a starting year",
  }),
  end_year: z.coerce.number().refine(value => value != null, {
    message: "Please select an ending year",
  }),
  start_month: z.coerce.number().refine(value => value != null, {
    message: "Please select starting month",
  }),
  end_month: z.coerce.number().refine(value => value != null, {
    message: "Please select an ending month",
  }),
});
