import { FC } from "react";
import CertificateForm from "~/components/forms/v2/jobseeker/Certificate";
import { certificate } from "~/types/jobseeker";
import { Card, CardTitle } from "../v2/Card";
import TruncatedText from "../v2/TruncatedText";
import { DefaultProps } from "./DefaultProps";
import LoadingItem from "./LoadingItem";

const Certificates: FC<DefaultProps> = ({
  data,
  loading,
  refetch,
  isEdition,
}) => {
  return (
    <Card className="gap-2 p-4">
      <CardTitle className="text-base">
        Certificates and Licenses
        {isEdition && <CertificateForm onSubmit={refetch} uid={data?.id} />}
      </CardTitle>
      {loading ? (
        <LoadingItem />
      ) : (
        data?.certificate?.map(certificate => (
          <CertificatesItem
            key={certificate.id}
            refetch={refetch}
            data={certificate}
            isEdition={isEdition}
          />
        ))
      )}
    </Card>
  );
};

interface CertificatesItemProps {
  data: certificate;
  refetch: () => void;
  isEdition: boolean;
}

const CertificatesItem: FC<CertificatesItemProps> = ({
  data,
  refetch,
  isEdition,
}) => {
  return (
    <div className="flex flex-col after:my-4 after:self-stretch after:border-b after:border-[#ccc] after:content-[''] last:after:m-0 last:after:border-0">
      <CardTitle className="text-base font-semibold">
        {data.certificate_license}{" "}
        {isEdition && (
          <CertificateForm
            uid={data?.seekerId}
            onSubmit={refetch}
            data={data}
          />
        )}
      </CardTitle>
      <div className="flex flex-col py-0">
        <CardTitle className="text-sm font-normal">
          {data.issuing_authority}
        </CardTitle>
        <TruncatedText
          className="text-sm text-[#595959]"
          text={data.description}
        />
      </div>
    </div>
  );
};

export default Certificates;
