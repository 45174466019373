'use client'
/* eslint-disable max-len */
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { z } from 'zod';

const containLowerCharacter = (value: any) => new RegExp('^(?=.*[a-z])').test(value);
const containUpperCharacter = (value: any) => new RegExp('^(?=.*[A-Z])').test(value);
const containSpecialCharacter = (value: any) =>
  new RegExp('^(?=.*[!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~])').test(value);
const containNumber = (value: any) => new RegExp('^(?=.*[0-9])').test(value);
const validatePhoneNumber = (value: any) => value  && isPossiblePhoneNumber?.(value);
const containsAlphabet = (value: any) => new RegExp('^(?=.*[a-zA-z])').test(value)
const length = (value: string, compareValue: any) => String(value).length === compareValue;
const minLength = (value: any, min: number) => new RegExp(`^(?=.{${min},})`).test(value);
const passwordValid = (value: any) => new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,16})").test(value);

const maxLength = (value: any, max: number) => new RegExp(`^(?=.{0,${max}}$)`).test(value);
const min = (value: number, compareValue: number) => Number(value) >= compareValue;
const max = (value: number, compareValue: number) => Number(value) <= compareValue;
const require = (value: any) => value;
const emailSchema = z.string().email();
const email = (value: any): boolean =>
  emailSchema.safeParse(value).success
const password = (value: any) =>
  new RegExp(
    // eslint-disable-next-line max-len
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~])(?=.{8,})',
  ).test(value);
const validSecureUrl = (value: any) =>
  // eslint-disable-next-line no-useless-escape
  new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g).test(value);

const exportedFunc = {
  require,
  email,
  password,
  length,
  minLength,
  passwordValid,
  maxLength,
  min,
  max,
  containNumber,
  containLowerCharacter,
  containsAlphabet,
  containUpperCharacter,
  containSpecialCharacter,
  validSecureUrl,
  validatePhoneNumber
}
export default exportedFunc;
