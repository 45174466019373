import { Gender } from "@prisma/client";
import { FC, PropsWithChildren, ReactNode } from "react";
import DemographicsForm from "~/components/forms/v2/jobseeker/Demographics";
import { raceEthnicity } from "~/types/jobseeker";
import { Card, CardTitle } from "../v2/Card";
import { Skeleton } from "../v2/Skeleton";
import { DefaultProps } from "./DefaultProps";
import Dialog from "./Dialog";

const genderMap: { [key in Gender]: string } = {
  MALE: "Male",
  FEMALE: "Female",
  NON__BINARY: "Non-binary",
  GENDER_NOT_LISTED: "Other",
  PREFER_NOT_TO_ANSWER: "Prefer not to answer",
};

const raceMap: { [key in raceEthnicity]: string } = {
  white: "White",
  black_or_african_amercian: "Black or African American",
  native_hawaiian_or_pacific_islander: "Native Hawaiian or Pacific Islander",
  native_american_or_alaska_native: "Native American or Alaska Native",
  middle_eastern: "Middle Eastern",
  asian: "Asian",
  hispani_or_latino: "Hispanic or Latino/Latina/LatinX",
  other_race: "Other race, ethnicity or origin",
  prefer_not_to_answer: "Prefer no to answer",
};

const handleJSONList = (list: raceEthnicity[]) => {
  if (Array.isArray(list)) return list.map(item => raceMap[item]).join(", ");
  return `${list}`;
};

const Demographics: FC<DefaultProps> = ({
  data,
  loading,
  refetch,
  isEdition,
}) => {
  return (
    <Card className="gap-2 p-4">
      <CardTitle className="text-base">
        Demographics
        {isEdition && (
          <DemographicsForm
            onSubmit={refetch}
            data={data?.job_seeker_basicinfo}
          />
        )}
      </CardTitle>
      <div className="flex flex-col gap-4">
        {data?.job_seeker_basicinfo?.race_ethinicity &&
          data.job_seeker_basicinfo.race_ethinicity.length > 0 && (
            <Item title="Race/ethnicity:">
              <span>
                {loading && <Skeleton className="h-3 w-32" />}
                {data?.job_seeker_basicinfo?.race_ethinicity
                  ? handleJSONList(data?.job_seeker_basicinfo?.race_ethinicity)
                  : ""}
              </span>
            </Item>
          )}
        {data?.job_seeker_basicinfo?.gender && (
          <Item title="Gender:">
            {loading ? (
              <Skeleton className="h-3 w-32" />
            ) : data?.job_seeker_basicinfo?.gender ? (
              genderMap[data?.job_seeker_basicinfo?.gender]
            ) : (
              "No informed"
            )}
          </Item>
        )}
        {data?.job_seeker_basicinfo?.veteran_status && (
          <Item title="Veteran:">
            {loading && <Skeleton className="h-3 w-32" />}
            <span className="capitalize">
              {data?.job_seeker_basicinfo?.veteran_status
                ?.replaceAll("_", " ")
                .toLowerCase()}
            </span>
          </Item>
        )}
        {data?.job_seeker_basicinfo?.non_traditional && (
          <Item
            title={
              <span className="flex gap-1">
                Non-traditional work history:
                <Dialog
                  infoDialog
                  trigger={<i className="mi-circle-information"></i>}
                  title="Non-traditional work history"
                  description={
                    <span className="flex flex-col gap-4 text-center">
                      <p className="text-xs">
                        Individuals with non-traditional work histories are
                        those who have been out of the workforce for more than 3
                        years or who have only part-time or seasonal job
                        experience but want to work in a full-time role
                      </p>
                      <p className="text-xs">
                        SCALIS has special processes in place to help place
                        these individuals with employers who are willing to
                        employ such individuals
                      </p>
                    </span>
                  }
                />
              </span>
            }
          >
            <span className="capitalize">
              {loading && <Skeleton className="h-3 w-32" />}
              {data?.job_seeker_basicinfo?.non_traditional
                ?.replaceAll("_", " ")
                ?.toLowerCase()}
            </span>
          </Item>
        )}
      </div>
    </Card>
  );
};

interface ItemProps {
  title: ReactNode;
}

const Item: FC<PropsWithChildren<ItemProps>> = ({ children, title }) => (
  <div className="text-sm text-[#000D3D]">
    <CardTitle className="text-base">{title}</CardTitle>
    {children}
  </div>
);

export default Demographics;
